import { useLocation } from "@reach/router";
import { Button, Form, Input, message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { navigate } from "gatsby";
import get from "lodash.get";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import ReactCodeInput from "react-verification-code-input";
import { feathers } from "../../../api/feathers_rest";
import { emailIcon } from "../../../images";
import { useTranslation } from "../../../locales/useTranslation";
import sendRegisterVerificationEmail from "../../../newApi/user/sendRegisterVerificationEmail";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// markup
const LoginPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  // const [showError, setShowError] = useState();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [emailCode, setEmailCode] = useState("");

  const isMediumScreen = useMediaQuery({ query: "(min-width: 360px)" });
  const inputWidth = isMediumScreen ? 58 : 30;

  useEffect(() => {
    let query = queryString.parse(location.search);
    if (!get(query, "email")) {
      message.error(t("emailRequired"));
      navigate(routes.forgotPassword.to());
    }
    if (!get(query, "username")) {
      message.error(t("usernameRequired"));
      navigate(routes.forgotPassword.to());
    }
    setEmail(get(query, "email"));
    setUsername(get(query, "username"));
  }, [location]);

  useEffect(() => {
    if (email) {
      sendEmailVerificationCode();
    }
  }, [email]);

  async function sendEmailVerificationCode() {
    setLoading(true);

    let mail = {
      to: email,
      subject: "IndoITS Reset Password Verification Code",
      text: "You have requested to reset your password with IndoITS. ",
      text2: "Here is your verification code: ",
    };
    sendRegisterVerificationEmail(mail)
      .then((res) => {
        if (get(res, "status") === 200) {
          const data = get(res, "data");

          let emailObject = {
            to: get(data, "email"),
            code: get(data, "emailCode"),
            type: 2,
          };

          feathers("emails")
            .create(emailObject)
            .then((res) => {
              setEmailCode((prevState) => {
                return get(data, "emailCode");
              });

              setEmail((prevState) => {
                return get(data, "email");
              });

              setTimeout(() => {
                setEmailCode((prevState) => {
                  return "";
                });
              }, get(data, "ttl"));

              message.success(
                // "代码已发去您的电邮箱. 过了 30 分钟您能再要求新的代码. Code has been sent to your email. You can request for another code after 30 minutes",
                t("sendEmailCodeDesc"),
                6
              );
              setLoading(false);

              //// send notification for client
              // let mail2 = {
              //   to: notificationEmail,
              //   subject: "Notification of Reset Password for User",
              //   text: ` ${get(
              //     data,
              //     "email"
              //   )}, has requested to reset password with the verification code -> ${get(
              //     data,
              //     "emailCode"
              //   )} `,
              //   type: "notification",
              // };

              // sendRegisterVerificationEmail(mail2)
              //   .then((res) => {})
              //   .catch((err) => {
              //     message.error(err);
              //   });
            })
            .catch((err) => {
              message.error(t("failAddEmailRecord"));
              setLoading(false);
            });
        } else {
          message.error(t("failSendEmail"));
          setLoading(false);
        }
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  }

  function handleSubmit() {
    form
      .validateFields()
      .then((values) => {
        if (values.checkCode == emailCode) {
          navigate(
            routes.resetPassword.to({
              email: email,
              username: username,
              checkCode: values.checkCode,
            })
          );
        } else {
          message.error(t("incorrectEmailCode"));
          form.resetFields();
        }
      })
      .catch((err) => {});
  }

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <div className="justify-end flex items-center">
                {/* 忘记密码 <MediaQuery maxWidth={440}><br/></MediaQuery>Forgot Password */}
                {t("forgotPassword2")}
              </div>
            }
            showBack={true}
          >
            <div className="p-4" style={{ height: "105vh" }}>
              <Form form={form} style={{ color: "white" }}>
                <div className="flex justify-center items-center">
                  <img src={emailIcon} style={{ width: 150, height: 150 }} />
                </div>
                <div className="my-5 text-center font-semibold text-xl">
                  {/* 验证您的电子邮件<br />Verify your email */}
                  {t("verifyEmail")}
                </div>
                <div className="my-5 text-center text-sm font-thin px-5">
                  {/* 请检查您的电子邮件以获取4位数代码以继续<br />Please check your mail to get a 6 digit code to continue */}
                  {t("checkYourMail")}
                </div>
                <div className="my-5 px-5 flex justify-center">
                  <Form.Item
                    name="checkCode"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {/* 请填写邮箱验证码<br /> */}
                            {t("emailCodeRequired")}
                          </React.Fragment>
                        ),
                      },
                      {
                        validator: (rule, value, callback) => {
                          // if (!value) {
                          //     callback(<React.Fragment>
                          //         请填写邮箱验证码<br />
                          //         Email Verification Code is required.
                          //     </React.Fragment>);
                          // } else if (value.length < 6) {
                          if (value.length < 6) {
                            callback(
                              <React.Fragment>
                                {/* 请填写邮箱验证码<br /> */}
                                {t("emailCodeRequired")}
                              </React.Fragment>
                            );
                          } else {
                            callback();
                          }
                        },
                      },
                    ]}
                  >
                    <ReactCodeInput
                      fieldWidth={inputWidth}
                      fieldHeight={inputWidth}
                      fields={6}
                      onChange={(val) => {
                        form.setFieldsValue({ checkCode: val });
                      }}
                    />
                    <Input style={{ display: "none" }} />
                  </Form.Item>
                </div>
                <div
                  className="my-5 underline text-center text-blue-400 px-5"
                  onClick={(e) => {
                    sendEmailVerificationCode();
                  }}
                >
                  {/* 重新发送代码 Resend code */}
                  {t("resendCode")}
                </div>
                <div className="my-5 flex justify-center items-center px-5">
                  <Button
                    className="main-button-color "
                    block
                    shape="round"
                    onClick={(e) => {
                      handleSubmit();
                    }}
                  >
                    {t("send")}
                  </Button>
                </div>
              </Form>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
